<div class="container-fluid spinner-container" [style]="isLoading ? 'display:block' : 'display:none'">
  <div class="insideContent">
    <div class="row align-items-center justify-content-center">
      <div style="color: #80B707" class="la-ball-fussion">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <p class="textInfo text-center">{{ title | translate}}</p>
    </div>
  </div>
</div>
