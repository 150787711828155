import { Component, OnInit } from '@angular/core';

import { TranslationService, ConfigurationsService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private configurationsService: ConfigurationsService
  ) {
  }
  async ngOnInit() {
    this.translationService.setLanguage();
    await this.configurationsService.loadConfigurations();
  }
}
