import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.scss']
})
export class PrivacyPoliciesComponent implements OnInit {

  constructor(private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.gaService.event('Visualización', '8.1. Política de privacidad');
  }

}
