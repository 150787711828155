import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { globalConfig } from '../../../../configs/global.config';

@Component({
  selector: 'app-footer-default',
  templateUrl: './footer-default.component.html',
  styleUrls: ['./footer-default.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterDefaultComponent implements OnInit {
  @ViewChild('externalPdfViewer') externalPdfViewer;
  contactInfo = globalConfig['contact-info'];
  modalContent: string | null;
  modalTitle: string | null;

  constructor() { }

  ngOnInit(): void {
    this.modalContent = null;
    this.modalTitle = null;
  }

  openModal(content: string, title?: string): void {
    this.modalContent = content;
    this.modalTitle = title ? title : null;
  }

  openPdf(): void {
    this.externalPdfViewer.pdfSrc = 'dinerofull-contrato.pdf';
    this.externalPdfViewer.refresh();
  }
  handleModalCloseClicked(): void {
    this.modalContent = null;
    this.modalTitle = null;
  }

}
