<agm-map id="map" [latitude]="selectedLatitude" [longitude]="selectedLongitude" [zoom]="15">
  <agm-marker *ngFor="let item of offices"
    [latitude]="item.latitude"
    [longitude]="item.longitude"
    [iconUrl]="item.id === selectedOffice ? selectedIconUrl : iconUrl">
  </agm-marker>
</agm-map>

<div>
  <mat-card *ngFor="let item of offices" class="example-card">
    <mat-card-title (click)="onClickOffice(item)" class="link-office">{{'contact.office' | translate}} {{item.office}}</mat-card-title>
    <mat-card-subtitle>{{item.address}}, {{item.location}}</mat-card-subtitle>
    <mat-card-subtitle *ngIf="item.workingHours">
      {{'contact.schedule' | translate}}: {{item.workingHours}}
      <br>
      {{'contact.phone' | translate}}: {{item.phone}} <span *ngIf="item.phone && item.mobile">/</span> {{item.mobile}}
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="!item.workingHours">
      {{'contact.phone' | translate}}: {{item.phone}} <span *ngIf="item.phone && item.mobile">/</span> {{item.mobile}}
    </mat-card-subtitle>
  </mat-card>
</div>
