/**
 * Global Config variables
 */
export const globalConfig = {
  translate: {
    languages: ['es', 'en'],
    langDefault: 'es',
    fillLanguage: [
      { value: 'en', label: 'English' },
      { value: 'es', label: 'Español' }
    ]
  },
  'contact-info': {
    phone: '0810-888-5876',
    'mobile-phone': [
      {
        number: '11-3443-8719',
        whatsappUrl: '1134438719'
      },
      {
        number: '11-2154-0699',
        whatsappUrl: '1121540699'
      }
    ],
    'e-mail': 'info@dinerofull.com.ar',
    'whatsapp-url': 'https://api.whatsapp.com/send?phone=549',
    address: 'Dardo Rocha 1318 piso 2 – San Isidro – Buenos Aires – Argentina.'
  },
  loans: {
    cookieName: 'currentStep'
  },
  user: {
    cookieName: 'crediweb_username'
  }
};
