import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { globalConfig } from '../../../../configs/global.config';

@Component({
  selector: 'app-float-icon',
  templateUrl: './float-icon.component.html',
  styleUrls: ['./float-icon.component.scss']
})
export class FloatIconComponent implements OnInit {
  contactInfo = globalConfig['contact-info'];
  mobileNumbers = this.contactInfo['mobile-phone'];
  mobilePhone:string;
  constructor(private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    const randomValue = Math.floor(Math.random() * this.mobileNumbers.length);
    this.mobilePhone = this.mobileNumbers[randomValue].whatsappUrl;
  }

  redirectExternalPage() {
    this.gaService.event('Click botón', '16.1. Botón Whatsapp');
  }

}
