import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AgmCoreModule } from '@agm/core';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';

import { environment} from '../../../environments/environment';
import { InternationalizationModule } from '../internationalization/internationalization.module';
import { CustomFontAwesomeModule } from '../custom-font-awesome/custom-font-awesome.module';
import {
  FaqsComponent,
  FloatIconComponent,
  ModalBoxComponent,
  TermsComponent,
  SpinnerLoaderComponent,
  UploadBoxComponent,
  ContactComponent,
  PrivacyPoliciesComponent,
  RegretButtonComponent,
  ClaimsButtonComponent,
  CancelButtonComponent,
  FinancialUserComponent,
  ConsumerDefenceComponent
} from './components';

@NgModule({
  declarations: [
    FaqsComponent,
    FloatIconComponent,
    ModalBoxComponent,
    TermsComponent,
    SpinnerLoaderComponent,
    UploadBoxComponent,
    ContactComponent,
    PrivacyPoliciesComponent,
    RegretButtonComponent,
    ClaimsButtonComponent,
    FinancialUserComponent,
    ConsumerDefenceComponent,
    CancelButtonComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    CustomFontAwesomeModule,
    InternationalizationModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.apiMap
    }),
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    FaqsComponent,
    FloatIconComponent,
    ModalBoxComponent,
    TermsComponent,
    SpinnerLoaderComponent,
    UploadBoxComponent,
    ContactComponent,
    PrivacyPoliciesComponent,
    RegretButtonComponent,
    ClaimsButtonComponent,
    FinancialUserComponent,
    ConsumerDefenceComponent,
    CancelButtonComponent
  ]
})
export class SharedModule { }
