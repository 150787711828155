import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalConstants } from '../../../../configs/global-constants';

@Component({
  selector: 'app-financial-user',
  templateUrl: './financial-user.component.html',
  styleUrls: ['./financial-user.component.scss']
})
export class FinancialUserComponent {
  constructor(
    private router: Router
  ) {}
  openRates(): Promise<boolean> {
    return this.router.navigateByUrl(GlobalConstants.loanRatesPreview);
  }

}
