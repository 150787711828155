<div class="modal-box-overlay">
  <div class="modal-box-content-container">
    <div class="modal-box-close-button" (click)="closeModal()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
    </div>
    <div class="modal-box-content" [class.full]="fullBody">
      <div class="modal-box-header" *ngIf="modalHeader">
        <div class="modal-box-title-container" *ngIf="title" [innerHtml]="title | translate"></div>
      </div>
      <div class="modal-box-body" >
        <div class="modal-box-body--content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
