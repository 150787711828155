import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.gaService.event('Visualización', '7.1. Términos y condiciones');
  }

}
