import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// @ts-ignore
import * as MasterJson from '../../assets/json-data/paths.json';

@Injectable({
  providedIn: 'root'
})
export class ReadingJsonService {

  constructor(
    private http: HttpClient
  ) { }

  public getAllObjects() {
    return MasterJson['default'];
  }

  public getDataByAlias(fileName: string) {
    return fileName === '' ? null : this.http.get(this.getAllObjects()[fileName]);
  }
}
