import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';


import { environment } from '../../../../environments/environment';
import * as fromRoot from '../../../reducers';
import * as fromConfigurations from './configurations.reducer';
import { Stepper, Message, Spinner, Bank  } from '../../../models/configurations.model';

export const layoutsFeatureKey = 'layouts';

export interface LayoutState {
  [fromConfigurations.configurationsFeatureKey]: fromConfigurations.State
}

export interface State extends fromRoot.AppState {
  [layoutsFeatureKey]: LayoutState
}

export function reducers(state: LayoutState | undefined, action: Action) {
  return combineReducers({
    [fromConfigurations.configurationsFeatureKey]: fromConfigurations.reducer
  })(state, action)
}

export const selectLayoutState = createFeatureSelector<State, LayoutState>(
  layoutsFeatureKey
);

export const selectStepper = createSelector(
  selectLayoutState,
  (state: LayoutState): Stepper => state[fromConfigurations.configurationsFeatureKey].stepper
);

export const selectMessage = createSelector(
  selectLayoutState,
  (state: LayoutState): Message => state[fromConfigurations.configurationsFeatureKey].message
);

export const selectSpinner = createSelector(
  selectLayoutState,
  (state: LayoutState): Spinner => state[fromConfigurations.configurationsFeatureKey].spinner
);

export const selectBanks = createSelector(
  selectLayoutState,
  (state: LayoutState): Bank[] => state[fromConfigurations.configurationsFeatureKey].banks
);

export const metaReducers: MetaReducer<LayoutState>[] = !environment.production ? [] : [];
