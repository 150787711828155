import { Action, createReducer, on } from '@ngrx/store';

import { ConfigurationActions } from '../actions';
import { Stepper, Message, Spinner, Bank  } from '../../../models/configurations.model';

export const configurationsFeatureKey = 'configurations';

export interface State {
  stepper: Stepper | null,
  message: Message | null,
  spinner: Spinner | null,
  banks: Bank[] | null
}

export const initialState: State = {
  stepper: null,
  message: null,
  spinner:null,
  banks: null
};

const configurationsReducer = createReducer(
  initialState,
  on(ConfigurationActions.loadStepper, (state, { stepper }) => ({ ...state, stepper })),
  on(ConfigurationActions.loadMessage, (state, { message }) => ({ ...state, message })),
  on(ConfigurationActions.loadSpinner, (state, { spinner }) => ({ ...state, spinner })),
  on(ConfigurationActions.loadBanks, (state, { banks }) => ({ ...state, banks }))
);

export function reducer(state: State | undefined, action: Action) {
  return configurationsReducer(state, action);
}



