import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function startsWithValidator(start: string, checkStart: boolean): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    const value: string = control.value;

    if (value) {
      if (checkStart && (value.length >= start.length && !value.startsWith(start))) {
        return { startsWith: { start, actualValue: value.substring(0, start.length) } };
      }

      if (!checkStart && (value.length >= start.length && value.startsWith(start))) {
        return { startsWith: { start, actualValue: value.substring(0, start.length) } };
      }
    }

    return null;

  };

}
