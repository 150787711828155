<div class="container-fluid footer-default">
  <div class="container-lg">
    <div class="row pt-5">
      <div class="col-md-6 col-xl-5 text-white footer-cta-group">
        <a class="d-flex align-items-center justify-content-between footer-cta cta-link" (click)="openModal('faqs', 'faqs.title')">
          <p> {{ 'menu.faqs' | translate }} </p>
          <p class="mr-2"> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </p>
        </a>
        <a class="d-flex align-items-center justify-content-between footer-cta mt-3 cta-link" (click)="openModal('terms', 'terms.title')">
          <p> {{ 'menu.conditions' | translate }} </p>
          <p class="mr-2"> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </p>
        </a>
        <a class="d-flex align-items-center justify-content-between footer-cta mt-3 cta-link" (click)="openModal('privacy-policy', 'privacyPolicy.title')">
          <p> {{ 'menu.privacyPolicy' | translate }} </p>
          <p class="mr-2"> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </p>
        </a>
        <a class="d-flex align-items-center justify-content-between footer-cta mt-3 cta-link" (click)="openModal('regret-button', 'regretButton.title')">
          <p> {{ 'menu.regretButton' | translate }} </p>
          <p class="mr-2"> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </p>
        </a>
        <a class="d-flex align-items-center justify-content-between footer-cta mt-3 cta-link" (click)="openModal('cancel-button', 'cancelButton.title')">
          <p> {{ 'menu.cancelButton' | translate }} </p>
          <p class="mr-2"> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </p>
        </a>
        <a class="d-flex align-items-center justify-content-between footer-cta mt-3 cta-link" (click)="openModal('claims-button', 'claimsButton.title')">
          <p> {{ 'menu.claimsButton' | translate }} </p>
          <p class="mr-2"> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </p>
        </a>
        <a class="d-flex align-items-center justify-content-between footer-cta mt-3 cta-link" (click)="openModal('consumerDefence', 'consumerDefence.title')">
          <p> {{ 'menu.consumerDefence' | translate }} </p>
          <p class="mr-2"> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </p>
        </a>
        <a class="d-flex align-items-center justify-content-between footer-cta mt-3 cta-link" (click)="openPdf()">
          <p> {{ 'menu.adhesionContract' | translate }} </p>
          <p class="mr-2"> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </p>
        </a>
        <a class="d-flex align-items-center justify-content-between footer-cta mt-3 cta-link" (click)="openModal('financialUser', 'financialUser.title')">
          <p> {{ 'menu.financialUser' | translate }} </p>
          <p class="mr-2"> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </p>
        </a>
      </div>
      <div class="col-md-6 col-xl-5 text-white footer-cta-group">
        <p class="contact-title">
          {{ 'menu.contact' | translate }}
        </p>
        <div class="d-none d-md-flex">
          <a class="d-flex align-items-center icon-text cta-link" [href]="'tel:' + contactInfo.phone">
            <fa-icon [icon]="['fas', 'phone-alt']" class="mr-2"></fa-icon> {{ contactInfo.phone }}
          </a>
          <span class="ml-3 d-flex align-items-center">
            <fa-icon [icon]="['fab', 'whatsapp']" class="mr-2 wp"></fa-icon>
            <a *ngFor="let mobile of contactInfo['mobile-phone']; let first = first;" [ngClass]="{'mr-3': first}" class="cta-link icon-text" target="_blank" [href]="contactInfo['whatsapp-url'] + mobile.whatsappUrl">
              {{ mobile.number }}
            </a>
          </span>
        </div>
        <div class="d-none d-md-none d-block">
          <a class="d-flex align-items-center icon-text cta-link" [href]="'tel:' + contactInfo.phone">
            <fa-icon [icon]="['fas', 'phone-alt']" class="mr-2"></fa-icon> {{ contactInfo.phone }}
          </a>
          <span class="d-flex align-items-center">
            <fa-icon [icon]="['fab', 'whatsapp']" class="mr-2 wp"></fa-icon>
            <a *ngFor="let mobile of contactInfo['mobile-phone']; let first = first;" [ngClass]="{'mr-3': first}" class="cta-link icon-text" target="_blank" [href]="contactInfo['whatsapp-url'] + mobile.whatsappUrl">
              {{ mobile.number }}
            </a>
          </span>
        </div>
        <div class="d-flex contact-email">
          <a class="d-flex align-items-center icon-text cta-link" target="_blank" [href]="'mailto:' + contactInfo['e-mail']">
            <fa-icon [icon]="['fas', 'envelope']" class="mr-2"></fa-icon> {{ contactInfo['e-mail'] }}
          </a>
        </div>
      </div>
      <div class="col-md-12 pt-2 mt-3 ">
        <div class="footer-disclaimer">
          <h4> {{ 'menu.legals' | translate }} </h4>
          <p [innerHTML]="'footer.disclaimer' | translate" class="disclaimer-text"></p>
          <div class="border-divider"></div>
          <div class="mt-4 d-flex footer-logos">
            <a href="http://qr.afip.gob.ar/?qr=G0t5RWUnvKg4AUBV6mucjQ,," target="_F960AFIPInfo">
              <img class="mr-3" src="assets/images/footer/data-fiscal.png">
            </a>
            <img class="mr-2" src="assets/images/footer/pdp.png">
          </div>
          <p class="mt-4 mb-4">
            {{ 'footer.copyright' | translate }}
          </p>
          <p class="mt-4 mb-4">
            {{ 'footer.dontPrint' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <app-modal-box *ngIf="modalContent" [title]="modalTitle" (closeClicked)="handleModalCloseClicked()">
    <app-faqs *ngIf="modalContent === 'faqs'"></app-faqs>
    <app-terms *ngIf="modalContent === 'terms'"></app-terms>
    <app-privacy-policies *ngIf="modalContent === 'privacy-policy'"></app-privacy-policies>
    <app-regret-button *ngIf="modalContent === 'regret-button'"></app-regret-button>
    <app-cancel-button *ngIf="modalContent === 'cancel-button'"></app-cancel-button>
    <app-claims-button *ngIf="modalContent === 'claims-button'"></app-claims-button>
    <app-consumer-defence *ngIf="modalContent === 'consumerDefence'"></app-consumer-defence>
    <app-financial-user *ngIf="modalContent === 'financialUser'"></app-financial-user>
  </app-modal-box>
  <ng2-pdfjs-viewer
    #externalPdfViewer
    [externalWindow]="true"
    locale="es-AR"
    [download]="false"
    openFile="false"
    [useOnlyCssZoom]=true
    [viewBookmark]="false"
    zoom="100"
    [showSpinner]="true"
    target="_myWindow">
  </ng2-pdfjs-viewer>
</div>
