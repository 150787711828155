import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromConfigurations from '../modules/layouts/reducers';
import { ConfigurationActions } from '../modules/layouts/actions';
import { Stepper, Message, Step, Spinner, Configurations, FileUpload } from '../models/configurations.model';
import { ReadingJsonService } from './reading-json.service';
import { GlobalConstants } from '../configs/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {

  constructor(
    private store: Store<fromConfigurations.State>,
    private readingJsonService: ReadingJsonService
  ) { }

  getStepsItems() {
    return this.store.pipe(select(fromConfigurations.selectStepper));
  }

  setStepsItems(value: Stepper | null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(ConfigurationActions.loadStepper({ stepper: value }));
      resolve(true);
    });
  }

  loadConfigurations() {
    return new Promise((resolve, reject) => {
      this.store.dispatch(ConfigurationActions.loadSpinner( { spinner: { isLoading: false } }));
      this.readingJsonService.getDataByAlias('configurations')
        .subscribe(
          (configurations: Configurations) => {
            this.store.dispatch(ConfigurationActions.loadStepper({ stepper: configurations.stepper }));
            resolve(true);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getOffices(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.readingJsonService.getDataByAlias('offices')
        .subscribe(
          (response) => {
            return resolve(response)
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateStepperSteps(steps, stepLabel: number): Step[] {
    steps.forEach((step) => {
      if (step.label < stepLabel && step.display) {
        step.active = false;
        step.success = true;
      }
      if (step.label === stepLabel && step.display) {
        step.active = true;
        step.success = false;
      }
      if (step.label > stepLabel && step.display) {
        step.active = false;
        step.success = false;
      }
    });
    return steps;
  }

  setMessage(value: Message | null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(ConfigurationActions.loadMessage( { message: value }));
      resolve(true);
    });
  }

  getMessage() {
    return this.store.pipe(select(fromConfigurations.selectMessage));
  }

  setSpinner(spinner: Spinner): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(ConfigurationActions.loadSpinner( { spinner }));
      resolve(true);
    });
  }

  getSpinner() {
    return this.store.pipe(select(fromConfigurations.selectSpinner));
  }

  getBanks() {
    return this.store.pipe(select(fromConfigurations.selectBanks));
  }

  getStepByName(steps: Step[], name): Step {
    return steps.find(
      (step) => step.name === name
    );
  }

  setMessageData(
    type='error',
    title='handleError.default.title',
    message='handleError.default.message',
    ctaText='handleError.default.ctaText',
    url=''
  ) {
    return {
      type,
      contents: {
        title,
        message,
        action: {
          text: ctaText,
          url
        }
      }
    };
  }

  getFilePath(extension): string {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

    const fileType = imageExtensions.indexOf(extension) >= 0 ? 'image' : 'file';
    return fileType === 'image' ? 'assets/images/upload-image.svg' : 'assets/images/upload-file.svg';
  }

  setUploadedFilesStatus(filesUploaded, filesToUploaded: FileUpload[]): FileUpload[] {
    filesToUploaded.forEach((fileUpload) => {
      const identifierFiles  = filesUploaded.filter(file => file['type'] === fileUpload.identifier);
      const isLoaded = identifierFiles && identifierFiles.length;

      let filePath:string = '';
      let fileName: string = '';
      if (isLoaded) {
        filePath = this.getFilePath(identifierFiles[0]['extension']);
        fileName = `${identifierFiles[0]['name']}.${identifierFiles[0]['extension']}`;
      }
      fileUpload.filePath = filePath;
      fileUpload.fileName = fileName;

    });

    return filesToUploaded;
  }

  getNextStepRequestLoan(checkedPhone: boolean, currentStep: Step, steps: Step[]): String {
    let nextStepName: string = '';
    if (currentStep.nextStep === GlobalConstants.pinGenerateStep) {
      const requestLoanStep = this.getStepByName(steps, GlobalConstants.requestLoanStep);
      nextStepName = checkedPhone ? requestLoanStep.nextStep : currentStep.nextStep;
    } else {
      nextStepName = currentStep.nextStep;
    }
    return nextStepName;
  }

}
