// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://www.dinerofull.com.ar/api',
  backendHost: 'https://www.dinerofull.com.ar',
  apiMap: 'AIzaSyDMn4mYsUkZwoshMQSwauFZg0sAz-Iqttc',
  callsQuantity: 30,
  callsInterval: 10000,
  maintenanceMode: false,
  homeSimulator: {
    floorAmount: 10000,
    ceilAmount: 100000,
    floorFee: 2,
    ceilFee: 9,
    stepAmount: 5000,
    stepFee: 1,
    showRates: false
  },
  selectOffer: {
    floorAmount: 3000,
    stepAmount: 1000
  },
  gaTrackingCode: 'UA-105576234-1',
  socialLogin: {
    enable: true,
    google: {
      clientId: '201606754463-8vm0gbbpeqn550hku6btgtr7lur8at0v.apps.googleusercontent.com'
    },
    facebook: {
      clientId: '235129581801404'
    }
  },
  externalChannels: {
    crezu: {
      utm_source: 'crezu marketing'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
