import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {Subscription} from 'rxjs';

import { ConfigurationsService } from '../../../../services';
import { Spinner } from '../../../../models/configurations.model';

@Component({
  selector: 'app-loan-steps-layout',
  templateUrl: './loan-steps-layout.component.html',
  styleUrls: ['./loan-steps-layout.component.scss']
})
export class LoanStepsLayoutComponent implements OnInit, OnDestroy, AfterContentChecked {
  subscriptions: Subscription[] = [];
  isLoading: boolean = false;
  title: string;

  constructor(
    private renderer: Renderer2,
    private configurationsService: ConfigurationsService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.renderer.removeClass(document.body, 'reminder-layout')
    this.renderer.removeClass(document.body, 'default-layout');
    this.renderer.addClass(document.body, 'loans-layout');
    this.getSpinnerData();
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  getSpinnerData() {
    const spinnerSubscription = this.configurationsService.getSpinner()
      .subscribe(
        (spinner: Spinner) => {
          this.isLoading = spinner.isLoading;
          this.title = spinner.title ? spinner.title : 'loading' ;
        },
        (error) => {
          console.log(error);
        }
      );
    this.subscriptions.push(spinnerSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
