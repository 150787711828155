import { PhoneValidator } from '../models/configurations.model';

export class GlobalConstants {
  // Url constants
  public static homePageUrl: string = '/home';
  public static messagePageUrl: string = '/loans/result';
  public static maintenancePageUrl: string = '/loans/en-mantenimiento';
  public static vioFinishValidationPageUrl: string = '/loans/external-validation-success';
  public static stepConfigurationUrl: string = '/loans/load-step-configuration';
  public static loanConfigurationUrl: string = '/loans/load-configurations';
  public static loanRatesPreview: string = '/loans/ver-tasas';

  // Steps constants
  public static vioStartStep: string = 'vio-start-validation';
  public static selectOfferStep: string = 'select-offer';
  public static daneiaScoreStep: string = 'daneia-score';
  public static requestLoanStep: string = 'request-loan';
  public static pinGenerateStep: string = 'pin-generate';
  public static summaryStep: string = 'summary';
  public static completeLoanStep: string = 'complete-loan';

  // Loan status constants
  public static loanCompletionStatuses = [
    'APPROVED', 'PRE_LIQUIDATED', 'LIQUIDATED', 'PRE_CANCELLED', 'CANCELLED', 'EXPIRED', 'WITHDRAWN'
  ];

  public static phoneValidator: PhoneValidator = {
    totalDigits: 10,
    areaCodeMinLength: 2,
    areaCodeMaxLength: 4,
    phoneMinLength: 6,
    phoneMaxLength: 8
  };
}
