<div class="p-0 terms-container">
  <ngb-accordion #a="ngbAccordion" [closeOthers]="true" activeIds="static-1">

    <ngb-panel id="custom-panel-1">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">

          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" id="term-one">
            <div class="float-left fix-margin-left">
              <h3 class="term-title breakLine">{{ "terms.term-one-title" | translate }}</h3>
            </div>
            <div class="float-right fix-margin-right">
              <fa-icon [icon]="opened ? ['fas','angle-up'] : ['fas','angle-down']"
                [class]="[opened ? 'upArrow' : 'downArrow']"></fa-icon>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p>{{ "terms.term-one-one" | translate }}</p>
        <p> {{ "terms.term-one-two" | translate }} </p>
        <p> {{ "terms.term-one-three" | translate }}</p>
        <p> {{ "terms.term-one-four" | translate }}</p>
        <p> {{ "terms.term-one-five" | translate }}</p>
        <p> {{ "terms.term-one-six" | translate }}</p>
        <p> {{ "terms.term-one-seven" | translate }}</p>
        <p> {{ "terms.term-one-eight" | translate }}</p>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="custom-panel-2">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">

          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" id="term-two">
            <div class="float-left fix-margin-left">
              <h3 class="term-title">{{ "terms.term-two-title" | translate }}</h3>
            </div>
            <div class="float-right fix-margin-right">
              <fa-icon [icon]="opened ? ['fas','angle-up'] : ['fas','angle-down']"
                [class]="[opened ? 'upArrow' : 'downArrow']"></fa-icon>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p>{{ "terms.term-two-one" | translate }}</p>
        <p> {{ "terms.term-two-two" | translate }} </p>
        <p> {{ "terms.term-two-three" | translate }}</p>
        <p> {{ "terms.term-two-four" | translate }}</p>
        <p> {{ "terms.term-two-five" | translate }}</p>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="custom-panel-3">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">

          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" id="term-three">
            <div class="float-left fix-margin-left">
              <h3 class="term-title">{{ "terms.term-three-title" | translate }}</h3>
            </div>
            <div class="float-right fix-margin-right">
              <fa-icon [icon]="opened ? ['fas','angle-up'] : ['fas','angle-down']"
                [class]="[opened ? 'upArrow' : 'downArrow']"></fa-icon>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p>{{ "terms.term-three-one" | translate }}</p>
        <p> {{ "terms.term-three-two" | translate }} </p>
        <p> {{ "terms.term-three-three" | translate }}</p>
        <p> {{ "terms.term-three-four" | translate }}</p>
        <p> {{ "terms.term-three-five" | translate }}</p>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="custom-panel-4">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">

          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" id="term-four">
            <div class="float-left fix-margin-left">
              <h3 class="term-title">{{ "terms.term-four-title" | translate }}</h3>
            </div>
            <div class="float-right fix-margin-right">
              <fa-icon [icon]="opened ? ['fas','angle-up'] : ['fas','angle-down']"
                [class]="[opened ? 'upArrow' : 'downArrow']"></fa-icon>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p>{{ "terms.term-four-one" | translate }}</p>
        <p> {{ "terms.term-four-two" | translate }} </p>
        <p> {{ "terms.term-four-three" | translate }}</p>
        <p> {{ "terms.term-four-four" | translate }}</p>
        <p> {{ "terms.term-four-six" | translate }}</p>
        <p> {{ "terms.term-four-seven" | translate }}</p>
        <p> {{ "terms.term-four-eight" | translate }}</p>
        <p> {{ "terms.term-four-nine" | translate }}</p>
        <p [innerHTML]="'terms.term-four-ten' | translate"></p>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="custom-panel-5">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">

          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" id="term-five">
            <div class="float-left fix-margin-left">
              <h3 class="term-title">{{ "terms.term-five-title" | translate }}</h3>
            </div>
            <div class="float-right fix-margin-right">
              <fa-icon [icon]="opened ? ['fas','angle-up'] : ['fas','angle-down']"
                [class]="[opened ? 'upArrow' : 'downArrow']"></fa-icon>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p>{{ "terms.term-five" | translate }}</p>
      </ng-template>
    </ngb-panel>

  </ngb-accordion>

</div>
