<div class="file-upload-box">
  <p  [class.light]="item.filePath" [class.error]="item.showErrors" [innerHTML]="item.title | translate"></p>
  <input
    class="file-input"
    type="file"
    name="file"
    accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    style="display: none;"
    (change)="onFileChange(fileInput.files[0])"
    #fileInput
  />

  <div class="display-input" [class.borderRed]="item.showErrors" *ngIf="!item.filePath" (click)="fileInput.click()">
    <span class="upload-hint">{{ "documentUpload.clickHint" | translate }}  </span>
  </div>
  <p *ngIf="item.showErrors && fileTypeError" class="error errorMagin">{{ 'documentUpload.error.fileType' | translate}}</p>
  <p *ngIf="item.showErrors && fileSizeError" class="error errorMagin">{{ 'documentUpload.error.fileSize' | translate}}</p>
  <p *ngIf="item.showErrors && item.validateError" class="error errorMagin">{{ 'documentUpload.error.required' | translate }}</p>
  <p *ngIf="item.showErrors && item.uploadError" class="error errorMagin">{{ 'documentUpload.error.upload' | translate }}</p>

  <div *ngIf="item.progress > 0 && item.isProcessing"  class="mt-3">
    <p class="uploading"><ngb-progressbar type="success" textType="white" [value]="item.progress" [showValue]="true" [striped]="true" [animated]="true"></ngb-progressbar></p>
  </div>
  <div class="uploaded-image-container">
    <div class="image-upload-file"  *ngIf="item.filePath && !item.isProcessing">
      <div class="close-button" (click)="removeFile()"> <fa-icon [icon]="['fas', 'times']"></fa-icon> </div>
      <div class="image-source">
        <img [src]="item.filePath" alt="Dinero Full">
      </div>
    </div>
    <div class="delete-hint" *ngIf="item.filePath && !item.isProcessing">
      <span *ngIf="!item.deleteError" class="delete" (click)="removeFile()"> {{ "documentUpload.delete" | translate }} </span>
      <span *ngIf="item.deleteError" class="error">{{ 'documentUpload.error.delete' | translate }}</span>
    </div>
  </div>
</div>
