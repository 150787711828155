import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { RequestDataForm, Simulator } from '../models/configurations.model';

@Injectable({
  providedIn: 'root'
})
export class LoansService {
  readonly API_URL = environment.apiUrl;
  constructor(
    private httpClient: HttpClient
  ) { }

  getSimulate(amount, fees): Observable<Simulator> {
    return this.httpClient.get<Simulator>(`${this.API_URL}/public/loans/simulate/${amount}/${fees}`);
  }

  getDataBanks() {
    return this.httpClient.get(`${this.API_URL}/masters/getData/bank`);
  }

  create(data) {
    return this.httpClient.post(`${this.API_URL}/public/loans`, data)
  }

  requestLogger(request, response) {
    const data = {
      'request': JSON.stringify(request),
      'response': JSON.stringify(response),
      'status_code': response.status ? response.status : null
    };
    return this.httpClient.post(`${this.API_URL}/request_logger/saveEvent`, data);
  }

  getLoanById(loanId) {
    return this.httpClient.get(`${this.API_URL}/public/loans/${loanId}/getLoanData`);
  }

  generatePin(loanId) {
    return this.httpClient.get(`${this.API_URL}/public/loans/${loanId}/refreshPin`);
  }

  validatePin(loanId: string, data) {
    return this.httpClient.post(`${this.API_URL}/public/loans/${loanId}/validatePin`, data);
  }

  update(loanId, data) {
    return this.httpClient.put(`${this.API_URL}/public/loans/${loanId}`, data);
  }

  changeEmail(loanId: string, data) {
    return this.httpClient.post(`${this.API_URL}/public/loans/${loanId}/changeMail`, data);
  }

  changePhone(clientId: string, data) {
    return this.httpClient.post(`${this.API_URL}/public/clients/${clientId}/changePhone`, data);
  }

  uploadFiles(loanId: string, fileType: string = 'unknown', data: any) {
    return this.httpClient.post(`${this.API_URL}/public/loans/${loanId}/files?fileType=${fileType}`, data, { reportProgress: true,
      observe: 'events'});
  }

  deleteFile(loanId: string, data: object) {
    return this.httpClient.post(`${this.API_URL}/public/loans/${loanId}/setFileAsDeleted`, data);
  }

  getUploadedFiles(loanId: string) {
    return this.httpClient.get(`${this.API_URL}/public/loans/${loanId}/files`);
  }

  getDaneiaScore(loanId: string) {
    return this.httpClient.get(`${this.API_URL}/public/loans/${loanId}/getDaneiaScore`);
  }

  approvedLoan(loanId: string) {
    const data = {
      'followingStatus': 'APPROVED',
      'statusReason': ''
    };
    return this.httpClient.put(`${this.API_URL}/public/loans/${loanId}/approved`, data);
  }

  getPreviousLoan(data) {
    return this.httpClient.post(`${this.API_URL}/public/loans/getPrevious`, data);
  }

  sendRequestLoan(data: RequestDataForm): Observable<object> {
    return this.httpClient.post(`${this.API_URL}/public/loans/createRequest`, data);
  }
}
