import { createAction, props } from '@ngrx/store';
import { Stepper, Message, Spinner, Bank  } from '../../../models/configurations.model';

export const loadStepper = createAction(
  '[Configurations] Load Steps',
  props<{ stepper: Stepper }>()
);

export const loadMessage = createAction(
  '[Configurations] Load Message',
  props<{ message: Message }>()
);

export const loadSpinner = createAction(
  '[Configurations] Load Spinner',
  props<{ spinner: Spinner }>()
);

export const loadBanks = createAction(
  '[Configurations] Load Banks',
  props<{ banks: Bank[] }>()
);
