import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { ConfigurationsService } from '../../../../services';
import { CurrentStepData, Stepper, Cookie, Step } from '../../../../models/configurations.model';
import { globalConfig } from '../../../../configs/global.config';
import { GlobalConstants } from '../../../../configs/global-constants';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  stepper: Stepper | null;
  steps: Step[] = [];
  currentStepData: CurrentStepData;
  displayReminder = false;
  userName: string;
  cookieName = globalConfig.loans.cookieName;
  cookieValue: Cookie | null = null;
  title: string = 'loading';
  isLoading = false;

  constructor(
    private renderer: Renderer2,
    private configurationsService: ConfigurationsService,
    private cookieService: CookieService,
    private router: Router,
    private gaService: GoogleAnalyticsService
  ) { }

  async ngOnInit() {
    this.renderer.removeClass(document.body, 'loans-layout');
    this.renderer.addClass(document.body, 'default-layout');
    if (this.cookieService.check(this.cookieName)) {
      this.isLoading = true;
      this.cookieValue = JSON.parse(this.cookieService.get(this.cookieName));
      this.displayReminder = true;
      this.userName = this.cookieValue.userName ? this.cookieValue.userName + ', ' : '';
    }
  }

  async removeCookieData() {
    this.configurationsService.loadConfigurations()
      .then(() => {
        this.displayReminder = false;
        this.cookieService.delete(this.cookieName, '/');
        this.cookieValue = null;
      });
  }

  openLoansSteps() {
    this.gaService.event('Click botón', '14.1. ¡Solicitá tu préstamo ya!');
    return this.router.navigateByUrl(GlobalConstants.loanConfigurationUrl);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
