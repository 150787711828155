<div *ngIf="displayReminder" class="d-flex fixed-top reminder-container justify-content-end">
  <div class="inside-row d-flex align-items-center">
    <div class="col-md-8 pr-0"><p class="align-fix"><strong>{{ userName }}</strong>{{ 'reminder.message' | translate }}</p></div>
    <div class="col-md-4 padding-fix">
      <button (click)="openLoansSteps()" class="continue-button">{{ 'reminder.yes' | translate }}</button>
      <button (click)="removeCookieData()" class="no-thanks-button">{{ 'reminder.no' | translate }}</button>
    </div>
  </div>
</div>
<app-header-default [displayReminder]="displayReminder"></app-header-default>
<div class="container-lg">
  <router-outlet></router-outlet>
</div>
<app-footer-default></app-footer-default>
