import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faAngleRight,
  faPhoneAlt,
  faEnvelope,
  faUser,
  faAngleDown,
  faAngleUp,
  faTimes,
  faArrowLeft,
  faExclamationCircle,
  faTimesCircle,
  faCheck,
  faHome
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookSquare, faGoogle } from '@fortawesome/free-brands-svg-icons';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [FontAwesomeModule]
})
export class CustomFontAwesomeModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      faAngleRight,
      faPhoneAlt,
      faEnvelope,
      faWhatsapp,
      faUser,
      faAngleDown,
      faAngleUp,
      faTimes,
      faArrowLeft,
      faExclamationCircle,
      faTimesCircle,
      faCheck,
      faFacebookSquare,
      faGoogle,
      faHome
    );
  }
}
