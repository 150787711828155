<div class="p-0 faqs-container">
  <ngb-accordion #a="ngbAccordion" [closeOthers]="true" activeIds="custom-panel-1">

    <ngb-panel id="custom-panel-1">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">

          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" id="faqs-title-one">
            <div class="float-left fix-margin-left">
              <h3 class="faq-title breakLine">{{ "faqs.faqs-one-title" | translate }}</h3>
            </div>
            <div class="float-right fix-margin-right">
              <fa-icon [icon]="opened ? ['fas','angle-up'] : ['fas','angle-down']"
                [class]="[opened ? 'upArrow' : 'downArrow']"></fa-icon>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p class="title-faq-inside" id="faqs-one-one-title">{{ "faqs.faqs-one-one-title" | translate }}</p>
        <p id="faqs-one-one-text">{{ "faqs.faqs-one-one-text" | translate }}</p>

        <p id="faqs-one-one-one-text">{{ "faqs.faqs-one-one-one-text" | translate }}</p>
        <p id="faqs-one-one-two-text">{{ "faqs.faqs-one-one-two-text" | translate }}</p>
        <p id="faqs-one-one-three-text">{{ "faqs.faqs-one-one-three-text" | translate }}</p>

        <p id="faqs-one-two-title" class="title-faq-inside">{{ "faqs.faqs-one-two-title" | translate }}</p>
        <p id="faqs-one-two-text">{{ "faqs.faqs-one-two-text" | translate }}</p>

        <p id="faqs-one-three-title" class="title-faq-inside">{{ "faqs.faqs-one-three-title" | translate }}</p>
        <p id="faqs-one-three-text">{{ "faqs.faqs-one-three-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-one-four-title">{{ "faqs.faqs-one-four-title" | translate }}</p>
        <p id="faqs-one-four-text">{{ "faqs.faqs-one-four-text" | translate }}</p>
        <p id="faqs-one-four-one-text">{{ "faqs.faqs-one-four-one-text" | translate }}</p>
        <p id="faqs-one-four-two-text">{{ "faqs.faqs-one-four-two-text" | translate }}</p>
        <p id="faqs-one-four-three-text">{{ "faqs.faqs-one-four-three-text" | translate }}</p>
        <p id="faqs-one-four-four-text">{{ "faqs.faqs-one-four-four-text" | translate }}</p>

        <p id="faqs-one-five-title" class="title-faq-inside">{{ "faqs.faqs-one-five-title" | translate }}</p>
        <p id="faqs-one-five-text">{{ "faqs.faqs-one-five-text" | translate }}</p>
        <p id="faqs-one-five-one-text">{{ "faqs.faqs-one-five-one-text" | translate }}</p>
        <p id="faqs-one-five-two-text">{{ "faqs.faqs-one-five-two-text" | translate }}</p>
        <p id="faqs-one-five-three-text">{{ "faqs.faqs-one-five-three-text" | translate }}</p>
        <p id="faqs-one-five-four-text">{{ "faqs.faqs-one-five-four-text" | translate }}</p>
        <p id="faqs-one-five-five-text">{{ "faqs.faqs-one-five-five-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-one-six-title">{{ "faqs.faqs-one-six-title" | translate }}</p>
        <p>{{ "faqs.faqs-one-six-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-one-seven-title">{{ "faqs.faqs-one-seven-title" | translate }}</p>
        <p id="faqs-one-seven-text">{{ "faqs.faqs-one-seven-text" | translate }}</p>

      </ng-template>
    </ngb-panel>

    <ngb-panel id="custom-panel-2">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">

          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" id="faqs-title-two">
            <div class="float-left fix-margin-left">
              <h3 class="faq-title breakLine">{{ "faqs.faqs-two-title" | translate }}</h3>
            </div>
            <div class="float-right fix-margin-right">
              <fa-icon [icon]="opened ? ['fas','angle-up'] : ['fas','angle-down']"
                [class]="[opened ? 'upArrow' : 'downArrow']"></fa-icon>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p id="faqs-two-one-title" class="title-faq-inside">{{ "faqs.faqs-two-one-title" | translate }}</p>
        <p id="faqs-two-one-text">{{ "faqs.faqs-two-one-text" | translate }}</p>
        <p id="faqs-two-one-one-text">{{ "faqs.faqs-two-one-one-text" | translate }}</p>
        <p id="faqs-two-one-two-text">{{ "faqs.faqs-two-one-two-text" | translate }}</p>
        <p id="faqs-two-one-three-text">{{ "faqs.faqs-two-one-three-text" | translate }}</p>
        <p id="faqs-two-one-four-text">{{ "faqs.faqs-two-one-four-text" | translate }}</p>
        <p id="faqs-two-one-five-text">{{ "faqs.faqs-two-one-five-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-two-two-title">{{ "faqs.faqs-two-two-title" | translate }}</p>
        <p id="faqs-two-two-text">{{ "faqs.faqs-two-two-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-two-three-title">{{ "faqs.faqs-two-three-title" | translate }}</p>
        <p id="faqs-two-three-text">{{ "faqs.faqs-two-three-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-two-four-title">{{ "faqs.faqs-two-four-title" | translate }}</p>
        <p id="faqs-two-four-text">{{ "faqs.faqs-two-four-text" | translate }}</p>

        <p id="faqs-two-five-title" class="title-faq-inside">{{ "faqs.faqs-two-five-title" | translate }}</p>
        <p id="faqs-two-five-text">{{ "faqs.faqs-two-five-text" | translate }}</p>
        <p id="faqs-two-five-one-text">{{ "faqs.faqs-two-five-one-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-two-six-title">{{ "faqs.faqs-two-six-title" | translate }}</p>
        <p id="faqs-two-six-text">{{ "faqs.faqs-two-six-text" | translate }}</p>

        <p id="faqs-two-seven-title" class="title-faq-inside">{{ "faqs.faqs-two-seven-title" | translate }}</p>
        <p id="faqs-two-seven-text">{{ "faqs.faqs-two-seven-text" | translate }}</p>
        <p id="faqs-two-seven-one-text">{{ "faqs.faqs-two-seven-one-text" | translate }}</p>
        <p id="faqs-two-seven-two-text">{{ "faqs.faqs-two-seven-two-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-two-eight-title">{{ "faqs.faqs-two-eight-title" | translate }}</p>
        <p id="faqs-two-eight-text">{{ "faqs.faqs-two-eight-text" | translate }}</p>
        <p id="faqs-two-eight-one-text">{{ "faqs.faqs-two-eight-one-text" | translate }}</p>
        <p id="faqs-two-eight-two-text">{{ "faqs.faqs-two-eight-two-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-two-nine-title">{{ "faqs.faqs-two-nine-title" | translate }}</p>
        <p id="faqs-two-nine-text">{{ "faqs.faqs-two-nine-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-two-ten-title">{{ "faqs.faqs-two-ten-title" | translate }}</p>
        <p id="faqs-two-ten-text">{{ "faqs.faqs-two-ten-text" | translate }}</p>
        <p id="faqs-one-five-two-text">{{ "faqs.faqs-one-five-two-text" | translate }}</p>
        <p id=faqs-one-five-three-text>{{ "faqs.faqs-one-five-three-text" | translate }}</p>
        <p id="faqs-one-five-four-text">{{ "faqs.faqs-one-five-four-text" | translate }}</p>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="custom-panel-3">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">

          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" id="faqs-title-three">
            <div class="float-left fix-margin-left">
              <h3 class="faq-title breakLine">{{ "faqs.faqs-three-title" | translate }}</h3>
            </div>
            <div class="float-right fix-margin-right">
              <fa-icon [icon]="opened ? ['fas','angle-up'] : ['fas','angle-down']"
                [class]="[opened ? 'upArrow' : 'downArrow']"></fa-icon>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>

        <p class="title-faq-inside" id="faqs-three-one-title">{{ "faqs.faqs-three-one-title" | translate }}</p>
        <p id="faqs-three-one-text">{{ "faqs.faqs-three-one-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-two-title">{{ "faqs.faqs-three-two-title" | translate }}</p>
        <p id="faqs-three-two-text">{{ "faqs.faqs-three-two-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-three-title">{{ "faqs.faqs-three-three-title" | translate }}</p>
        <p id="faqs-three-three-text">{{ "faqs.faqs-three-three-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-four-title">{{ "faqs.faqs-three-four-title" | translate }}</p>
        <p id="faqs-three-four-text">{{ "faqs.faqs-three-four-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-five-title">{{ "faqs.faqs-three-five-title" | translate }}</p>
        <p id="faqs-three-five-text">{{ "faqs.faqs-three-five-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-six-title">{{ "faqs.faqs-three-six-title" | translate }}</p>
        <p id="faqs-three-six-text">{{ "faqs.faqs-three-six-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-seven-title">{{ "faqs.faqs-three-seven-title" | translate }}</p>
        <p id="faqs-three-seven-text">{{ "faqs.faqs-three-seven-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-eight-title">{{ "faqs.faqs-three-eight-title" | translate }}</p>
        <p id="faqs-three-eight-text">{{ "faqs.faqs-three-eight-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-nine-title">{{ "faqs.faqs-three-nine-title" | translate }}</p>
        <p id="faqs-three-nine-text">{{ "faqs.faqs-three-nine-text" | translate }}</p>
        <p id="faqs-one-five-two-text">{{ "faqs.faqs-one-five-two-text" | translate }}</p>
        <p id="faqs-one-five-three-text">{{ "faqs.faqs-one-five-three-text" | translate }}</p>
        <p id="faqs-one-five-four-text">{{ "faqs.faqs-one-five-four-text" | translate }}</p>
        <p id="faqs-three-nine-one-text">{{ "faqs.faqs-three-nine-one-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-ten-title">{{ "faqs.faqs-three-ten-title" | translate }}</p>
        <p id="faqs-three-ten-text">{{ "faqs.faqs-three-ten-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-eleven-title">{{ "faqs.faqs-three-eleven-title" | translate }}</p>
        <p id="faqs-three-eleven-text">{{ "faqs.faqs-three-eleven-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-twelve-title">{{ "faqs.faqs-three-twelve-title" | translate }}</p>
        <p id="faqs-three-twelve-text">{{ "faqs.faqs-three-twelve-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-thirteen-title">{{ "faqs.faqs-three-thirteen-title" | translate }}</p>
        <p id="faqs-three-thirteen-text">{{ "faqs.faqs-three-thirteen-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-fourteen-title">{{ "faqs.faqs-three-fourteen-title" | translate }}</p>
        <p id="faqs-three-fourteen-text">{{ "faqs.faqs-three-fourteen-text" | translate }}</p>
        <p id="faqs-one-five-two-text">{{ "faqs.faqs-one-five-two-text" | translate }}</p>
        <p id="faqs-one-five-three-text">{{ "faqs.faqs-one-five-three-text" | translate }}</p>
        <p id="faqs-one-five-four-text">{{ "faqs.faqs-one-five-four-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-fifteen-title">{{ "faqs.faqs-three-fifteen-title" | translate }}</p>
        <p id="faqs-three-fifteen-text">{{ "faqs.faqs-three-fifteen-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-sixteen-title">{{ "faqs.faqs-three-sixteen-title" | translate }}</p>
        <p id="faqs-three-sixteen-text">{{ "faqs.faqs-three-sixteen-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-eighteen-title">{{ "faqs.faqs-three-eighteen-title" | translate }}</p>
        <p id="faqs-three-eighteen-text">{{ "faqs.faqs-three-eighteen-text" | translate }}</p>

        <p class="title-faq-inside" id="faqs-three-nineteen-title">{{ "faqs.faqs-three-nineteen-title" | translate }}</p>
        <p id="faqs-three-nineteen-text">{{ "faqs.faqs-three-nineteen-text" | translate }}</p>

      </ng-template>
    </ngb-panel>

  </ngb-accordion>

</div>
