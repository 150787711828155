export * from './faqs/faqs.component';
export * from './float-icon/float-icon.component';
export * from './modal-box/modal-box.component';
export * from './terms/terms.component';
export * from './spinner-loader/spinner-loader.component';
export * from './upload-box/upload-box.component';
export * from './contact/contact.component';
export * from './privacy-policies/privacy-policies.component';
export * from './regret-button/regret-button.component';
export * from './claims-button/claims-button.component';
export * from './cancel-button/cancel-button.component';
export * from './financial-user/financial-user.component';
export * from './consumer-defence/consumer-defence.component';
