import { Component, Input, OnInit } from '@angular/core';

import { Step } from '../../../../models/configurations.model';

@Component({
  selector: 'app-loan-steps',
  templateUrl: './loan-steps.component.html',
  styleUrls: ['./loan-steps.component.scss']
})
export class LoanStepsComponent implements OnInit {
  @Input()steps: Step[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
