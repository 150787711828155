import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-box',
  templateUrl: './modal-box.component.html',
  styleUrls: ['./modal-box.component.scss']
})
export class ModalBoxComponent implements OnInit {

  @Input('title') title: string;
  @Input('modalHeader') modalHeader: boolean = true;
  @Input('fullBody') fullBody: boolean = false;

  
  @Output() closeClicked: EventEmitter<Boolean> = new EventEmitter;
  constructor() { }

  ngOnInit(): void {
  }

  closeModal() {
    this.closeClicked.emit(true);
  }

}
