import { FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

export function phoneTotalDigitsValidator(requiredLength: number): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const areaCode: string = form.get('areaCode').value || '';
    const phone: string = form.get('phone').value || '';
    const actualLength = areaCode.length + phone.length;

    if (actualLength !== requiredLength) {
      return { phoneTotalDigits: { requiredLength, actualLength } };
    }

    return null;
  };
}
