import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { GlobalConstants } from '../../../../configs/global-constants';
import { RequestDataForm } from '../../../../models/configurations.model';
import { LoansService } from '../../../../services';
import { emailValidator } from '../../validators/email.validator';
import { phoneTotalDigitsValidator } from '../../validators/phoneTotalDigits.validator';
import { startsWithValidator } from '../../validators/startsWith.validator';

@Component({
  selector: 'app-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss']
})
export class CancelButtonComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  displayForm = false;
  submitted = false;
  displayAlertSuccess = false;
  displayAlertDanger = false;
  contactFullName = '';
  requestDataForm: FormGroup;
  formData: RequestDataForm;
  areaCodeMinLength = GlobalConstants.phoneValidator.areaCodeMinLength;
  areaCodeMaxLength = GlobalConstants.phoneValidator.areaCodeMaxLength;
  phoneMinLength = GlobalConstants.phoneValidator.phoneMinLength;
  phoneMaxLength = GlobalConstants.phoneValidator.phoneMaxLength;
  constructor(
    private gaService: GoogleAnalyticsService,
    private formBuilder: FormBuilder,
    private loansService: LoansService
  ) { }

  ngOnInit(): void {
    this.gaService.event('Visualización', '12.1. Botón de baja');
    this.createForm();
  }
  createForm(): void {
    this.requestDataForm = this.formBuilder.group({
      fullName: [null, [Validators.required]],
      dni: [null, [Validators.required, Validators.minLength(7), Validators.maxLength(8)]],
      email: [null, [Validators.required, emailValidator()]],
      areaCode: [null,
        [
          Validators.required,
          Validators.minLength(this.areaCodeMinLength),
          Validators.pattern('^([1-9][0-9]*|0)$')
        ]
      ],
      phone: [null,
        [
          Validators.required, Validators.minLength(this.phoneMinLength),
          Validators.pattern('^[0-9]*$'),
          startsWithValidator('15', false)
        ]
      ],
      reason: [null, [Validators.required]]
    }, { updateOn: 'submit', validators: [phoneTotalDigitsValidator(GlobalConstants.phoneValidator.totalDigits)] });
    this.displayForm = true;
  }

  get fields(): { [p: string]: AbstractControl } { return this.requestDataForm.controls; }

  isPhoneNumberInvalid(): boolean {
    return !!(this.requestDataForm.errors?.['phoneTotalDigits'])
      && this.requestDataForm.controls.areaCode.valid
      && this.requestDataForm.controls.phone.valid;
  }

  onFormSubmit(): void {
    this.submitted = true;

    if (this.requestDataForm.valid) {
      this.gaService.event('Click botón', '12.2. Botón de baja');
      const formValue = this.requestDataForm.value;
      this.formData = {
        type: 'cancel',
        fullName: formValue.fullName.trim(),
        email: formValue.email.toLowerCase().trim(),
        dni: formValue.dni,
        areaCode: formValue.areaCode,
        phone: formValue.phone,
        reason: formValue.reason.trim()
      };

      this.contactFullName = formValue.fullName;
      const sendSubscription = this.loansService.sendRequestLoan(this.formData)
        .subscribe(
          () => {
            this.displayAlertSuccess = true;
            this.resetForm(true);
          },
          (error) => {
            console.log(error.error);
            this.displayAlertDanger = true;
            this.resetForm(false);
          }
        );
      this.subscriptions.push(sendSubscription);
    }
  }

  resetForm(isSuccess: boolean): void {
    setTimeout(() => {
      this.submitted = false;
      this.requestDataForm.reset();
      const container = document.getElementById('form-container');
      container.scrollIntoView();
    }, 100);

    setTimeout(() => {
      if (isSuccess) {
        this.displayAlertSuccess = false;
      } else {
        this.displayAlertDanger = false;
      }
    }, 12000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
