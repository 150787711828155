import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { ConfigurationsService } from '../../../../services';
import { Office } from '../../../../models/configurations.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  offices: Office[] = [];
  selectedLatitude: number;
  selectedLongitude: number;
  selectedIconUrl = 'assets/images/globo_verde.svg';
  iconUrl = 'assets/images/globo_azul.svg';
  selectedOffice: number;

  constructor(
    private configurationsService: ConfigurationsService,
    private gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.gaService.event('Visualización', '11.1. Sucursales');
    this.configurationsService.getOffices()
      .then(response => {
        this.offices = response;
        this.selectedOffice = this.offices[0].id;
        this.selectedLatitude = this.offices[0].latitude;
        this.selectedLongitude = this.offices[0].longitude;
      });
  }

  onClickOffice(office: Office) {
    this.selectedOffice = office.id;
    this.selectedLatitude = office.latitude;
    this.selectedLongitude = office.longitude;

    if (this.selectedOffice > 2) {
      setTimeout(() => {
        const map = document.getElementById("map");
        map.scrollIntoView();
      }, 100);
    }
  }
}
