import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Step, Stepper } from '../../../../models/configurations.model';
import { ConfigurationsService } from '../../../../services';
import { GlobalConstants } from '../../../../configs/global-constants';

@Component({
  selector: 'app-header-loan-steps',
  templateUrl: './header-loan-steps.component.html',
  styleUrls: ['./header-loan-steps.component.scss']
})
export class HeaderLoanStepsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  stepper: Stepper | null = null;
  currentStep: Step;
  displayBackCta = false;

  constructor(
    private configurationsService: ConfigurationsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    const stepperSubscription = this.configurationsService.getStepsItems()
      .subscribe(
        (stepper: Stepper) => {
          this.stepper = stepper;
          this.currentStep = this.stepper.currentStep;
          if (this.currentStep) {
            this.displayBackCta = this.currentStep.displayBackCta;
          } else if (this.stepper.isPreviewPage) {
            this.displayBackCta = true;
          }
        },
        error => {
          console.log(error);
          return this.router.navigateByUrl(GlobalConstants.homePageUrl);
        }
      );

    this.subscriptions.push(stepperSubscription);
  }

  goToAction() {
    if (this.stepper.isMessagePage) {
      if (this.stepper.customBackUrl) {
        const redirectUrl = this.currentStep.backUrl;
        if (redirectUrl.startsWith('|')) {
          const stepName = redirectUrl.split('|')[2];
          return this.router.navigateByUrl(GlobalConstants.stepConfigurationUrl, { state: { stepName } });
        } else{
          return this.router.navigateByUrl(redirectUrl);
        }
      }
      if (this.stepper.finishProcess) {
        return this.router.navigateByUrl(GlobalConstants.homePageUrl);
      }
      return this.router.navigateByUrl(GlobalConstants.stepConfigurationUrl, { state: { stepName: this.currentStep.name } });
    } else if (this.stepper.isPreviewPage) {
      return this.router.navigateByUrl(GlobalConstants.homePageUrl);
    } else {
      if (this.currentStep.shouldBackStep) {
        return this.router.navigateByUrl(GlobalConstants.stepConfigurationUrl, { state: { stepName: this.currentStep.previousStep } });
      } else {
        return this.router.navigateByUrl(GlobalConstants.homePageUrl);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
