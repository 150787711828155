import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  DefaultLayoutComponent,
  LoanStepsLayoutComponent
} from './modules/layouts/pages';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/features/modules/home/home.module').then(module => module.HomeModule)
      }
    ]
  },
  {
    path: 'loans',
    component: LoanStepsLayoutComponent,
    loadChildren: () =>
      import('./modules/features/modules/loans/loans.module').then(module => module.LoansModule)
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
