import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  opened = false;

  active = "";

  constructor(private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.gaService.event('Visualización', '4.1. Preguntas Frecuentes');
  }

}
