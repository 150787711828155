<nav class="navbar navbar-light fixed-top header-loans mobile-version d-none d-flex d-md-none d-flex flex-row justify-content-between">
  <div class="header-left d-flex flex-row justify-content-between">
    <fa-icon [icon]="['fas', 'arrow-left']" class="back-icon" *ngIf="displayBackCta" (click)="goToAction()"></fa-icon>
    <img src="assets/images/logo-mobile.png" alt="Dinero Full">
  </div>
  <app-loan-steps [steps]="stepper.steps" *ngIf="stepper && stepper.display"></app-loan-steps>
</nav>
<div class="d-none d-md-flex fixed-top header-loans desktop-version">
  <div class="container-lg d-flex flex-row justify-content-between">
    <div class="header-left d-flex flex-row justify-content-between">
      <fa-icon [icon]="['fas', 'arrow-left']" class="back-icon" *ngIf="displayBackCta" (click)="goToAction()"></fa-icon>
      <img src="assets/images/logo.png" alt="Dinero Full">
    </div>
    <app-loan-steps [steps]="stepper.steps" *ngIf="stepper && stepper.display"></app-loan-steps>
  </div>
</div>
