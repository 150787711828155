import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { globalConfig } from '../configs/global.config';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  currentLang: string;
  translateConfig = globalConfig.translate;

  /**
   * Translate Service Constructor
   *
   * @param translate: TranslateService
   */
  constructor(private translate: TranslateService) { }

  /**
   * Setup language
   *
   * @param lang: string
   */
  setLanguage(lang?: string) {
    if (!lang) {
      lang = this.translateConfig.langDefault;
      this.translate.addLangs(this.translateConfig.languages);
      this.translate.setDefaultLang(lang);
    }

    this.currentLang = lang;
    this.translate.use(lang);
  }

  /**
   * Returns current language
   */
  getCurrentLanguage(): string {
    this.currentLang = this.translate.currentLang;
    return this.currentLang;
  }
}
