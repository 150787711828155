<p> {{ 'claimsButton.first-paragraph' | translate }}</p>
<div class="claims-cta-group">
  <a class="d-flex align-items-center icon-text cta-link" [href]="'tel:' + contactInfo.phone">
    <fa-icon [icon]="['fas', 'phone-alt']" class="mr-2"></fa-icon> {{ contactInfo.phone }}
  </a>
  <span class="d-flex align-items-center">
    <fa-icon [icon]="['fab', 'whatsapp']" class="mr-2 wp"></fa-icon>
    <a *ngFor="let mobile of contactInfo['mobile-phone']; let first = first;" [ngClass]="{'mr-3': first}" class="cta-link icon-text" target="_blank" [href]="contactInfo['whatsapp-url'] + mobile.whatsappUrl">
      {{ mobile.number }}
    </a>
  </span>
  <div class="d-flex contact-email">
    <a class="d-flex align-items-center icon-text cta-link" target="_blank" [href]="'mailto:' + contactInfo['e-mail']">
      <fa-icon [icon]="['fas', 'envelope']" class="mr-2"></fa-icon> {{ contactInfo['e-mail'] }}
    </a>
  </div>
  <a class="d-flex align-items-center icon-text cta-link" [href]="'tel:' + contactInfo.phone">
    <i class="fa-solid fa-location-dot"></i>
    <fa-icon [icon]="['fas', 'home']" class="mr-2"></fa-icon> {{ contactInfo.address }}
  </a>
</div>
<div class="form-container" id="form-container">
  <form [formGroup]="requestDataForm" (ngSubmit)="onFormSubmit()" *ngIf="displayForm">
    <div class="alert alert-success" role="alert" *ngIf="displayAlertSuccess">
      <h4 class="alert-heading">Consulta enviada</h4>
      <p><b>{{ contactFullName }}</b>, tu consulta fue enviada con éxito</p>
      <hr>
      <p class="mb-0">Muy pronto, te estaremos contactando desde nuestros centro de atención al cliente.</p>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="displayAlertDanger">
      <h4 class="alert-heading">Consulta NO Enviada</h4>
      <p><b>{{ contactFullName }}</b>, hubo problemas al enviar tu consulta</p>
      <hr>
      <p class="mb-0">Intenta mas tarde</p>
    </div>
    <div class="form-group">
      <label for="fullName">Nombre:</label>
      <input type="text" class="form-control" id="fullName"
             formControlName="fullName"
             [ngClass]="{ 'is-invalid': submitted && fields.fullName.invalid }">
      <div class="invalid-feedback" *ngIf="submitted && fields.fullName.invalid">
        Ingrese nombre
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="email">Email:</label>
        <input type="email" class="form-control" id="email"
               formControlName="email"
               [ngClass]="{ 'is-invalid': submitted && fields.email.invalid }">
        <div class="invalid-feedback" *ngIf="submitted && fields.email.invalid">
          {{ "firstStepForm.email-label-error" | translate }}
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="dni">DNI:</label>
        <input type="text" class="form-control" id="dni"
               formControlName="dni"
               mask="separator.0"
               thousandSeparator="."
               maxlength="10"
               [ngClass]="{ 'is-invalid': submitted && fields.dni.invalid }">
        <div class="invalid-feedback" *ngIf="submitted && fields.dni.invalid">
          {{ "firstStepForm.dni-label-error-one" | translate }}
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="areaCode">Código de área:</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="groupAreaCode">0</span>
          </div>
          <input type="email" class="form-control" id="areaCode"
                 formControlName="areaCode"
                 oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                 [maxlength]="areaCodeMaxLength"
                 [minlength]="areaCodeMinLength"
                 aria-describedby="groupAreaCode"
                 autocomplete="off"
                 [ngClass]="{ 'is-invalid': submitted && (fields.areaCode.invalid || isPhoneNumberInvalid()) }">
        </div>
        <small class="form-text text-muted" *ngIf="!submitted || (submitted && !fields.areaCode.hasError('pattern'))">
          Ingrese código de área sin el 0
        </small>
        <div class="invalid-group" *ngIf="submitted && (fields.areaCode.invalid && !fields.areaCode.hasError('pattern'))">
          ¡Código de área incorrecto!
        </div>
        <div class="invalid-group" *ngIf="submitted && fields.areaCode.hasError('pattern')">
          Ingrese código de área sin el 0
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="phone">Télefono:</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="groupPhone">15</span>
          </div>
          <input type="text" class="form-control" id="phone"
                 formControlName="phone"
                 oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                 [maxlength]="phoneMaxLength"
                 [minlength]="phoneMinLength"
                 aria-describedby="groupPhone"
                 autocomplete="off"
                 [ngClass]="{ 'is-invalid': submitted && (fields.areaCode.invalid || isPhoneNumberInvalid()) }">
        </div>
        <small class="form-text text-muted" *ngIf="!submitted || (submitted && !fields.phone.hasError('startsWith'))">
          Ingrese número de télefono sin el 15
        </small>
        <div class="invalid-group" *ngIf="submitted && (fields.phone.invalid && !fields.phone.hasError('startsWith'))">
          ¡Número de télefono incorrecto!
        </div>
        <div class="invalid-group" *ngIf="submitted && fields.phone.hasError('startsWith')">
          Ingrese número de télefono sin el 15
        </div>
      </div>
      <div class="invalid-group" *ngIf="submitted && isPhoneNumberInvalid()">
        {{ "firstStepForm.cellphone-label-error" | translate }}
      </div>
    </div>
    <div class="form-group">
      <label for="reason">Consulta:</label>
      <textarea class="form-control" id="reason"
                formControlName="reason"
                [ngClass]="{ 'is-invalid': submitted && fields.reason.invalid }">
      </textarea>
      <small class="form-text text-muted" *ngIf="!submitted">
        Ingrese los detalles de su consulta.
      </small>
      <div class="invalid-feedback" *ngIf="submitted && fields.reason.invalid">
        Ingrese los detalles de su consulta.
      </div>
    </div>
    <div class="d-flex align-items-center flex-column justify-content-center">
      <button type="submit" class="btn btnSubmit" [disabled]="submitted && requestDataForm.valid">Enviar Consulta</button>
    </div>
  </form>
</div>
