<!-- Contact channels -->
<div [innerHTML]="'financialUser.subtitleContactChannel' | translate"></div>

<div class="mt-3"> {{'financialUser.mail' | translate}} </div>

<div class="mt-3"> {{'financialUser.phone' | translate}} </div>

<div class="mt-3"> {{'financialUser.chat' | translate}} </div>

<!-- Financial user information -->
<div [innerHTML]="'financialUser.subtitleFinancialUser' | translate" class="mt-5"> </div>

<div class="mt-3"> {{'financialUser.titular' | translate}} </div>

<div class="mt-3"> {{'financialUser.alternate' | translate}} </div>

<div class="mt-3"> {{'financialUser.workAddress' | translate}} </div>

<div class="mt-3"> {{'financialUser.financialUserMail' | translate}} </div>

<div class="mt-3"> {{'financialUser.financialUserPhone' | translate}} </div>

<!-- Information -->
<div class="mt-5"> {{'financialUser.contact' | translate}} </div>

<div [innerHTML]="'financialUser.desagree' | translate" class="mt-3"> </div>

<div class="mt-3 mb-3">
  <p class="modal-box-title">Tus derechos como usuario de servicios financieros</p>
  <a href="https://www.bcra.gob.ar/bcrayvos/usuarios_financieros.asp" target="_blank">
    <img src="assets/images/footer/contacto-bcra.png" width="300" alt="Contacte con el BCRA">
  </a>
  <a href="https://www.bcra.gob.ar/bcrayvos/usuarios_financieros.asp" target="_blank">
    <img src="assets/images/footer/baja-productos-bcra.png" width="300" alt="Baja de productos y servicios">
  </a>
</div>
<div>
  <a title="Productos y Tasas" (click)="openRates()" class="rates">Productos y Tasas</a>
</div>
