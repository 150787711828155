import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { globalConfig } from '../../../../configs/global.config';
import { environment } from '../../../../../environments/environment';
import { GlobalConstants } from '../../../../configs/global-constants';

@Component({
  selector: 'app-header-default',
  templateUrl: './header-default.component.html',
  styleUrls: ['./header-default.component.scss']
})
export class HeaderDefaultComponent implements OnInit {
  isMenuCollapsed = true;
  scroll = false;
  contactInfo = globalConfig['contact-info'];
  modalContent: string | null;
  modalTitle: string | null;
  @Input() displayReminder: boolean;
  userNameCookie = globalConfig.user.cookieName;
  userName: string;
  loginUrl = `${environment.backendHost}/login`;
  logoutUrl = `${environment.backendHost}/logout`;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private gaService: GoogleAnalyticsService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.fragment
      .subscribe(
        (fragment: string) => {
          switch (fragment) {
            case 'faqs':
              this.openModal('faqs', 'faqs.title');
              break;
            case 'terms':
              this.openModal('terms', 'terms.title');
              break;
            case 'how-does-it-work':
              setTimeout(() => {
                const howDoesItWork = document.querySelectorAll('.loan-steps-title');
                howDoesItWork[0].scrollIntoView({block: 'center'});
              }, 200);
              break;
            case 'privacy-policy':
              this.openModal('privacy-policy', 'privacyPolicy.title');
              break;
            case 'contact':
              this.openModal('contact', 'contact.title');
              break;
            default:
              this.modalContent = null;
              this.modalTitle = null;
              break;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    window.addEventListener('scroll', this.scrolling, true);

    if (this.cookieService.check(this.userNameCookie)) {
      const cookieVal = this.cookieService.get(this.userNameCookie);
      this.userName =  cookieVal.replace(/^./, cookieVal[0].toUpperCase());
      this.userName = this.userName.split(' ')[0];
    }

  }

  openModal(content: string, title?: string): void {
    this.isMenuCollapsed = true;
    this.modalContent = content;
    this.modalTitle = title ? title : null;
  }

  handleModalCloseClicked(): void {
    this.modalContent = null;
    this.modalTitle = null;
  }

  scrolling = (element): void => {
    const scrollTop = element.target.scrollingElement ? element.target.scrollingElement.scrollTop : 500;
    if (this.displayReminder) {
      this.scroll = scrollTop >= 60;
    } else {
      this.scroll = scrollTop >= 20;
    }
  }

  displayMenu(): void {
    this.isMenuCollapsed = false;
    this.renderer.addClass(document.body, 'fixed-element');
  }

  hideMenu(): void {
    this.isMenuCollapsed = true;
    this.renderer.removeClass(document.body, 'fixed-element');
  }

  redirectExternalPage(url): void {
    switch (url){
      case 'login':
      case 'mi-cuenta':
        window.location.href = this.loginUrl;
        break;
      case 'logout':
        window.location.href = this.logoutUrl;
        break;
      default:
        window.location.href = this.loginUrl;
        break;
    }

  }

  openLoansSteps(): Promise<boolean> {
    this.gaService.event('Click botón', '13.1. ¡Solicitá tu préstamo ya! - Mobile');
    return this.router.navigateByUrl(GlobalConstants.loanConfigurationUrl);
  }

  toggleBackGroundDropdown(): void{
    const dropdown = document.querySelectorAll('.btn-group');
    dropdown[0].classList.toggle('text-active');
    }
}
