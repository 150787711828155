<nav class="navbar navbar-expand-md navbar-light bg-faded fixed-top header-default-mobile d-none d-flex d-md-none" [ngClass]="{'header-inverse': scroll || !isMenuCollapsed, 'reminder-margin': displayReminder}">
  <button class="navbar-toggler hidden-sm-up" type="button" *ngIf="isMenuCollapsed" (click)="displayMenu()">
    &#9776;
  </button>
  <img *ngIf="!isMenuCollapsed" alt="Cerrar" src="assets/images/close-mobile.svg" (click)="hideMenu()">
  <img src="assets/images/logo-mobile.png" alt="Dinero Full">
  <button type="button" class="btn get-loan" (click)="openLoansSteps()">{{ 'header.getLoanCta' | translate }}</button>

  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" [ngClass]="{'full-height': !isMenuCollapsed}">
    <div class="menu-mobile-container">
      <div *ngIf="userName" class="menu-mobile-item d-flex flex-row justify-content-between">
        <span>{{ 'menu.hello' | translate }}{{ userName }}</span>
      </div>
      <div class="menu-mobile-item d-flex flex-row justify-content-between" (click)="openLoansSteps()">
        <span>{{ 'menu.getLoan' | translate }}</span><fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
      </div>
      <div class="menu-mobile-item d-flex flex-row justify-content-between" *ngIf="!userName" (click)="redirectExternalPage('login')">
        <span>{{ 'menu.login' | translate }}</span><fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
      </div>
      <div class="menu-mobile-item d-flex flex-row justify-content-between" *ngIf="userName" (click)="redirectExternalPage('mi-cuenta')">
        <span>{{ 'menu.myAccount' | translate }}</span><fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
      </div>
      <div class="menu-mobile-item d-flex flex-row justify-content-between" (click)="openModal('contact', 'contact.title')">
        <span>{{ 'menu.contact' | translate }}</span><fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
      </div>
      <div class="menu-mobile-item d-flex flex-row justify-content-between" (click)="openModal('faqs', 'faqs.title')">
        <span>{{ 'menu.faqs' | translate }}</span><fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
      </div>
      <div class="menu-mobile-item d-flex flex-row justify-content-between" (click)="openModal('terms', 'terms.title')">
        <span>{{ 'menu.conditions' | translate }}</span><fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
      </div>
      <div class="menu-mobile-item d-flex flex-row justify-content-between" *ngIf="userName" (click)="redirectExternalPage('logout')">
        <span>{{ 'menu.logout' | translate }}</span><fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
      </div>
    </div>
    <div class="contact-container">
      <a class="cta-link" [href]="'tel:' + contactInfo.phone">
        <fa-icon [icon]="['fas', 'phone-alt']" class="fa-icon"></fa-icon>
        {{ contactInfo.phone }}
      </a>
      <span class="cta-link d-flex align-items-center">
        <fa-icon [icon]="['fab', 'whatsapp']" class="fa-icon whatsapp-icon"></fa-icon>
        <a *ngFor="let mobile of contactInfo['mobile-phone']; let first = first;" [ngClass]="{'mr-3': first}" target="_blank" class="mobile-number" [href]="contactInfo['whatsapp-url'] + mobile['whatsappUrl']">
          {{ mobile['number'] }}
        </a>
      </span>
      <a class="cta-link" target="_blank" [href]="'mailto:' + contactInfo['e-mail']">
        <fa-icon [icon]="['fas', 'envelope']" class="fa-icon"></fa-icon>
        {{ contactInfo['e-mail'] }}
      </a>
    </div>
  </div>
</nav>
<div class="d-none d-md-flex fixed-top header-default-desktop" [ngClass]="{'header-inverse': scroll, 'reminder-margin': displayReminder}">
  <div class="col-12 d-flex flex-row justify-content-between">
    <div class="col-lg-3 col-md-2 img-container">
      <img src="assets/images/logo.png" alt="Dinero Full">
    </div>
    <div class="col-lg-9 col-md-10 d-flex flex-row justify-content-end menu-container ">
      <ul class="nav nav-block">
        <li class="nav-item">
          <a class="nav-link" (click)="openModal('faqs', 'faqs.title')" [title]="'menu.faqs' | translate">{{ 'menu.faqs' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="openModal('claims-button', 'menu.contact')" [title]="'menu.contact' | translate">{{ 'menu.contact' | translate }}</a>
        </li>
      </ul>
      <button type="button" class="btn get-loan" [title]="'header.getLoanCta' | translate" (click)="openLoansSteps()">{{ 'header.getLoanCta' | translate }}</button>
      <div class="header-login" *ngIf="!userName">
        <a [href]="loginUrl" class="login-text" [title]="'menu.login' | translate">{{ 'menu.login' | translate }}</a>
        <fa-icon [icon]="['fas', 'user']" style="margin-left: 5px;"></fa-icon>
      </div>
        <div class="header-login btn-group" *ngIf="userName" [autoClose]="false" ngbDropdown (click)="toggleBackGroundDropdown()">
          <a ngbDropdownToggle class="login-text dropdown-toggle" [title]="'menu.myAccount' | translate">{{ 'menu.hello' | translate }}{{ userName }}<span class="caret"></span></a>
          <ul id="dropdown-basic" ngbDropdownMenu class="dropdown-menu"
            role="menu" aria-labelledby="button-basic">
            <li ngbDropdownItem role="menuitem"><a class="dropdown-item" [href]="loginUrl">Mi Cuenta</a></li>
            <li class="divider dropdown-divider"></li>
            <li ngbDropdownItem role="menuitem"><a class="dropdown-item" [href]="logoutUrl">Cerrar sesión</a></li>
          </ul>
        </div>
    </div>
  </div>
</div>

<app-modal-box *ngIf="modalContent" [title]="modalTitle" (closeClicked)="handleModalCloseClicked()">
  <app-faqs *ngIf="modalContent == 'faqs'"></app-faqs>
  <app-terms *ngIf="modalContent == 'terms'"></app-terms>
  <app-claims-button *ngIf="modalContent === 'claims-button'"></app-claims-button>
  <app-privacy-policies *ngIf="modalContent == 'privacy-policy'"></app-privacy-policies>
</app-modal-box>
