import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { InternationalizationModule } from '../internationalization/internationalization.module';
import { CustomFontAwesomeModule } from '../custom-font-awesome/custom-font-awesome.module';
import { SharedModule } from '../shared/shared.module';
import {
  DefaultLayoutComponent,
  LoanStepsLayoutComponent
} from './pages';
import {
  HeaderDefaultComponent,
  FooterDefaultComponent,
  HeaderLoanStepsComponent,
  LoanStepsComponent
} from './components';
import * as fromLayouts from './reducers';


@NgModule({
  declarations: [
    DefaultLayoutComponent,
    LoanStepsLayoutComponent,
    HeaderDefaultComponent,
    FooterDefaultComponent,
    HeaderLoanStepsComponent,
    LoanStepsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    InternationalizationModule.forRoot(),
    CustomFontAwesomeModule,
    NgbModule,
    SharedModule,
    PdfJsViewerModule,
    StoreModule.forFeature(fromLayouts.layoutsFeatureKey, fromLayouts.reducers, { metaReducers: fromLayouts.metaReducers }),
  ]
})
export class LayoutsModule { }
