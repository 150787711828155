import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUpload } from '../../../../models/configurations.model';

@Component({
  selector: 'app-upload-box',
  templateUrl: './upload-box.component.html',
  styleUrls: ['./upload-box.component.scss'],
})
export class UploadBoxComponent implements OnInit {
  @Input() item: FileUpload;
  @Output() uploadFile: EventEmitter<any> = new EventEmitter();
  @Output() deleteFile: EventEmitter<any> = new EventEmitter();

  fileTypeError = false;
  fileSizeError = false;

  constructor() {}

  ngOnInit(): void {}

  onFileChange(file) {
    const fileTypesDocs = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const patternImage = /image-*/;
    this.item.showErrors = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    if (!file.type.match(patternImage) && fileTypesDocs.indexOf(file.type) === -1) {
      this.item.showErrors = true;
      this.fileTypeError = true;
    }

    if (file.size > 8388608) {
      this.item.showErrors = true;
      this.fileSizeError = true;
    }

    if (!this.item.showErrors) {
      this.uploadFile.emit(file);
    }
  }

  removeFile() {
    this.deleteFile.emit(this.item);
  }
}
